import { Component, computed, input } from '@angular/core';
import { NumericPipe } from '@logic-suite/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipRow } from './tooltip-row';

@Component({
  selector: 'app-widget-graph-tooltip',
  standalone: true,
  imports: [TranslateModule, NumericPipe],
  template: `
    <table>
      @if (header()) {
        <thead>
          <tr class="header">
            <th [attr.colspan]="headerColSpan()">{{ headerRow().name | translate }}</th>
            @if (headerRow().value) {
              <th>{{ +headerRow().value | numeric: 'decimal' : headerRow().decimals || 2 }} {{ headerRow().unit }}</th>
            }
            @if (headerRow().extra) {
              <th>{{ +headerRow().extra! | numeric: 'decimal' : headerRow().decimals || 2 }} {{ headerRow().unit }}</th>
            }
          </tr>
        </thead>
      }
      <tbody>
        @for (row of rows(); track row) {
          @if (row.type === 'line') {
            <tr>
              <td [attr.colspan]="rowColumnSize()" class="line"></td>
            </tr>
          } @else {
            <tr>
              @if (row.name) {
                <th>{{ row.name | translate }}</th>
                @if (row.color) {
                  <td><span class="bullet" [style]="'color: ' + row.color">●</span></td>
                }
              }
              @if (row.value) {
                <td class="number">{{ +row.value | numeric: 'decimal' : row.decimals || 2 }} {{ row.unit }}</td>
              }
              @if (row.extra) {
                @if (row.extraPrefix) {
                  <th>/ Acc</th>
                }
                <td class="number">{{ +row.extra | numeric: 'decimal' : row.decimals || 2 }} {{ row.unit }}</td>
              }
            </tr>
          }
        }
      </tbody>
      @if (footer()) {
        <tbody>
          <tr class="footer">
            <th [attr.colspan]="footerColSpan()">{{ footerRow().name | translate }}</th>
            @if (footerRow().value) {
              <th>{{ +footerRow().value | numeric: 'decimal' : footerRow().decimals }} {{ footerRow().unit }}</th>
            }
            @if (footerRow().extra) {
              <th>{{ +footerRow().extra! | numeric: 'decimal' : footerRow().decimals }} {{ footerRow().unit }}</th>
            }
          </tr>
        </tbody>
      }
    </table>
  `,
  styles: ``,
})
export class WidgetGraphTooltipComponent {
  header = input<TooltipRow | string>('');
  footer = input<TooltipRow | string>('');
  rows = input<TooltipRow[]>([] as any[]);

  headerRow = computed(() => {
    return Object.assign(
      {
        decimals: 2,
        unit: '',
      },
      typeof this.header() === 'string'
        ? ({ name: this.header() as string } as TooltipRow)
        : (this.header() as TooltipRow),
    );
  });
  headerColSpan = computed(() => {
    const row = this.headerRow();
    return (
      1 + this.rowColumnSize() - [row.name, row.value, row.color, row.extraPrefix, row.extra].filter((v) => !!v).length
    );
  });

  footerRow = computed(() => {
    return Object.assign(
      {
        decimals: 2,
        unit: '',
      },
      typeof this.footer() === 'string'
        ? ({ name: this.footer() as string } as TooltipRow)
        : (this.footer() as TooltipRow),
    );
  });
  footerColSpan = computed(() => {
    const row = this.footerRow();
    return (
      1 + this.rowColumnSize() - [row.name, row.value, row.color, row.extraPrefix, row.extra].filter((v) => !!v).length
    );
  });

  rowColumnSize = computed(() =>
    Math.max(
      ...this.rows().reduce((acc, row) => {
        return acc.add([row.name, row.value, row.color, row.extraPrefix, row.extra].filter((v) => !!v).length);
      }, new Set<number>()),
    ),
  );
}
