import { Component, Input, HostBinding, ElementRef, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-icon-beta',
  styles: [
    `
      :host {
        display: inline-block;
        border: 1.4px solid currentColor;
        border-radius: 2.4px;
        margin: 0 0.5rem;
      }
    `,
  ],
  template: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="25 25 70 70" [attr.width]="size" [attr.height]="size">
    <path
      fill="currentColor"
      stroke-width="1.5"
      stroke="currentColor"
      d="M44.4,60.1V63c0,2.5,0.1,6,0.5,7.8h-3.5c-0.5-1.4-0.6-4.3-0.6-7.4V43.6c0-5.9,1.6-8.6,3.3-10.1 c1.5-1.4,3.4-2.3,6.2-2.3c4.2,0,7.6,3,7.6,7.5c0,3.3-1.8,5.7-3.8,6.7v0.1c3.3,0.7,6.4,3.5,6.4,8.3c0,5.8-4.4,8.9-9.1,8.9 c-2.9,0-5-0.9-6.6-2.6L44.4,60.1L44.4,60.1z M44.4,56.3c1,1.6,3.1,3.5,6.1,3.5c3.5,0,5.9-2.5,5.9-6c0-4.2-2.9-6.2-6.7-6.4 c-0.4-0.1-0.8,0-1.1,0v-2.6c0.3,0,0.5-0.1,0.7-0.1c3.3-0.2,4.9-2.8,4.9-5.5c0-3-1.8-5-4.4-5c-3.5,0-5.4,3-5.4,9L44.4,56.3 C44.4,56.3,44.4,56.3,44.4,56.3z"
    />
  </svg>`,
})
export class IconBetaComponent implements AfterViewInit {
  @HostBinding('class.betaIcon') isBeta = true;

  @Input() size = 19;
  @Input() color = '#003e31';

  @HostBinding('title')
  get title() {
    return this.translate.instant('This is a beta version. Errors may occur.');
  }

  constructor(private el: ElementRef, private translate: TranslateService) {}

  ngAfterViewInit() {
    this.el.nativeElement.style.width = this.size - 3 + 'px';
    this.el.nativeElement.style.height = this.size - 3 + 'px';
  }
}
