import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { WidgetRepository } from './widget-repository.service';
import { AccessService } from '../../access';

@Component({
  selector: 'app-widget-link',
  styles: ['a { font-size: inherit }'],
  template: `<a [routerLink]="[route]" [queryParams]="params">{{ name | translate }}</a>`,
})
export class WidgetLinkComponent implements OnInit, OnDestroy {
  @Input() widgetName!: string;
  subscriptions: Subscription[] = [];
  route!: string;
  name!: string;
  get params() {
    return (window.location.search || '')
      .substring(1)
      .split('&')
      .reduce((prev: Record<string, string>, curr) => {
        const [key, val] = curr.split('=');
        prev[key] = val;
        return prev;
      }, {});
  }
  constructor(
    private translate: TranslateService,
    private widgetRepository: WidgetRepository,
    private access: AccessService,
  ) {}

  ngOnInit() {
    this.getRoute(this.widgetName).subscribe(
      route => (this.route = this.access.getCurrentApplication().applicationUrl + route),
    );
    this.name = this.getWidget(this.widgetName);
  }

  private getWidget(name: string) {
    return this.translate.instant(name);
  }

  private getRoute(name: string) {
    return this.widgetRepository.route(name);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
