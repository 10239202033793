import { Injectable } from '@angular/core';
import { Route, LoadChildrenCallback, Routes } from '@angular/router';

import { BehaviorSubject, Observable, from } from 'rxjs';
import { map, filter, first } from 'rxjs/operators';

export type RouteLoaderCallback = () => Promise<Route[]> | Observable<Route[]>;
export interface WidgetDef {
  path: string;
  loadChildren: LoadChildrenCallback;
  data?: {
    animation: string;
    widget: string;
    beta: boolean;
    alternatives: string[];
    type: string;
  };
}

/**
 * Contains a repository of available widgets. It is dynamically built using
 * the routes from `dashboard.routing`
 *
 * In order to place a widget dynamically on screen according to
 * a configurational array of component names, we have to map each
 * name to an actual component. This is done here.
 */
@Injectable({ providedIn: 'root' })
export class WidgetRepository {
  private widgetRoutes$ = new BehaviorSubject<Route[]>([]);
  private repository$ = new BehaviorSubject<Map<string, WidgetDef>>(new Map<string, WidgetDef>());

  setRoutes(loadRoutes: RouteLoaderCallback) {
    const mapper = (route: Route[]): Map<string, WidgetDef> => {
      const acc = new Map<string, WidgetDef>();

      return (
        route.reduce((prev: Map<string, WidgetDef>, c: Route) => {
          if (c.data?.widget) {
            // Store widget to repository
            prev.set(c.data.widget, c as WidgetDef);
            // Store alternatives to repository
            if (c.data.alternatives) {
              c.children = c.data.alternatives.map((alt: any) => {
                const child = Object.assign({}, c as WidgetDef, {
                  path: `${alt}`,
                  loadChildren: c.loadChildren,
                  data: {
                    beta: c.data?.beta,
                    animation: c.data?.animation,
                    widget: `${c.data?.widget}-${alt}`,
                    type: alt,
                  },
                });
                return child;
              });
            }
          }
          if (c.children) {
            return new Map([...prev, ...mapper(c.children)]);
          }
          return prev;
        }, acc) ?? acc
      );
    };

    // Avoid circular dependency
    this.widgetRoutes$;
    from(loadRoutes())
      .pipe(first())
      .subscribe(routes => {
        // Store routes
        this.widgetRoutes$.next(routes);

        // Map up repository
        this.repository$.next(mapper(routes));
      });
  }

  get(name: string): Observable<WidgetDef | undefined> {
    return this.repository$.pipe(
      filter(r => !!r),
      map(repository => {
        return repository.get(name);
      })
    );
  }

  route(name: string): Observable<string> {
    return this.repository$.pipe(
      filter(r => !!r),
      map(repository => {
        const comp = repository.get(name);
        if (!comp) {
          return '';
        }

        const createUrl = (routes: Routes): string => {
          for (const route of routes) {
            // First pass - look through top level urls
            if (route.data?.widget === name) {
              return route.path || '';
            }
          }
          for (const route of routes) {
            // Second pass - look through child level urls
            if (route.children) {
              const r = createUrl(route.children);
              if (r != null) {
                return `${route.path}${r ? '/' + r : ''}`;
              }
            }
          }
          return '';
        };
        const children = this.widgetRoutes$.value;
        return children ? createUrl(children) : '';
      })
    );
  }
}
