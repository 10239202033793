import {
  Directive,
  Input,
  ViewContainerRef,
  ComponentRef,
  OnDestroy,
  OnInit,
  Compiler,
  Injector,
  NgModuleFactory,
  Type,
  ComponentFactoryResolver,
} from '@angular/core';
import { WidgetConfig } from './widget-config';
import { AbstractWidgetComponent } from './abstract-widget.component';
import { WidgetRepository, WidgetDef } from './widget-repository.service';
import { Subscription } from 'rxjs';
import { NotFoundComponent } from './not-found.component';

/**
 * Directive creating widget components by module.
 *
 * This will dynamically load the component with module by lookup in our widget-repository.
 */
@Directive({
  selector: '[appWidgetContainer]',
})
export class WidgetContainerDirective implements OnInit, OnDestroy {
  /** The name of the component in our repository */
  @Input() appWidgetContainer!: WidgetConfig;
  subscriptions: Subscription[] = [];

  /** The rendered component */
  componentRef!: ComponentRef<any>;

  /**
   *
   * @param view the placeholder for where we should render our component
   * @param resolver the service which fetches factory methods for creating components
   */
  constructor(
    private view: ViewContainerRef,
    private widgetRepository: WidgetRepository,
    private injector: Injector,
    private compiler: Compiler
  ) {}

  ngOnInit() {
    // this.view.clear();
    // Dynamically create a component by name
    this.subscriptions.push(
      this.widgetRepository
        .get(this.appWidgetContainer.componentName)
        .subscribe(async (component: WidgetDef | undefined) => {
          this.view.clear();
          if (component) {
            // Load module
            const module = await component.loadChildren();
            const moduleFactory = await this.loadModuleFactory(module);
            const moduleRef = moduleFactory.create(this.injector);

            // Create the modules bootstrap component
            const componentType: Type<AbstractWidgetComponent> = (moduleRef as { [key: string]: any })[
              '_bootstrapComponents'
            ][0];
            const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(componentType);

            // Render component
            this.componentRef = this.view.createComponent(componentFactory);
            this.appWidgetContainer.componentRef = this.componentRef;
            (this.componentRef.instance as AbstractWidgetComponent).inputData = component.data;
          } else {
            // Render not found component
            const componentFactory = this.injector
              .get(ComponentFactoryResolver)
              .resolveComponentFactory(NotFoundComponent);
            this.componentRef = this.view.createComponent(componentFactory);
            this.appWidgetContainer.componentRef = this.componentRef;
            (this.componentRef.instance as AbstractWidgetComponent).inputData = this.appWidgetContainer.componentName;
          }
        })
    );
  }

  private async loadModuleFactory(t: any) {
    if (t instanceof NgModuleFactory) {
      return t;
    } else {
      // TODO: Replace this
      return await this.compiler.compileModuleAsync(t);
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
