import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';

import { WarnComponent } from '../../feedback';
import { AbstractWidgetComponent } from './abstract-widget.component';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [WarnComponent, TranslateModule],
  template: ` <app-warn>{{ inputData | translate }} {{ 'widget not found' | translate }}</app-warn> `,
  styles: [],
})
export class NotFoundComponent extends AbstractWidgetComponent {
  loadData() {
    return of([]);
  }
}
