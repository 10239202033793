import { DragDrop } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  computed,
  contentChildren,
  inject,
  input,
  model,
  signal,
  viewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTab } from '@angular/material/tabs';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, combineLatest, firstValueFrom, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AssetTreeService } from '../../../shared/nav/asset-tree';
import { AbstractWidgetComponent } from './abstract-widget.component';
import { WidgetService } from './widget.service';

/**
 * A common GUI for all widgets.
 * Without this, your widget will not be draggable.
 */
@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit, OnDestroy {
  private readonly el = inject(ElementRef);
  private readonly dialog = inject(MatDialog);
  private readonly translate = inject(TranslateService);
  private readonly tree = inject(AssetTreeService);
  private readonly widget = inject(WidgetService);
  private readonly drag = inject(DragDrop);
  private readonly storage = inject(ApplicationStorageService);

  subtitle = input('');
  subheader = input('');
  wrapper = input.required<AbstractWidgetComponent>(); // The widget implementation
  /** Set this to true if the widget is not yet assigned to a feature package */
  forceDisplay = model(false); // Forces bypass of the feature package check
  header = computed(() => this.translate.instant(this.wrapper().fullWidgetName));
  backHeader = computed(() => this.wrapper().backTitle);
  route = computed(() => this.wrapper().routePath);
  queryParams = computed(() => this.wrapper().params);
  private settingsRef = viewChild.required<TemplateRef<any>>('settings');
  private __tabs = contentChildren(MatTab);

  isGraphFocused = signal(this.storage.getItem('widget.graphFocused', false));
  @HostBinding('class.graph-focused')
  get graphFocused() {
    return this.isGraphFocused() && this.wrapper().chartObj() != null;
  }

  @HostBinding('class.flipped')
  get showSettings() {
    return this.$showSettings.value;
  }
  set showSettings(flag: boolean) {
    this.$showSettings.next(flag);
    if (flag) this.wrapper().backTitle = this.__tabs()[0]?.textLabel ?? '';
  }

  @HostBinding('class.beta')
  get isBeta() {
    return this.wrapper()?.inputData?.beta;
  }

  @HostBinding('class.readonly')
  get isReadOnly() {
    return this.wrapper()?.inputData?.readOnly;
  }

  @HostBinding('class.fullscreen')
  get fullscreen() {
    return this.wrapper().isFullscreen();
  }

  $showSettings = new BehaviorSubject(false);
  canDisplay$ = this.tree.nodeSelection$.pipe(
    map(node => node.type),
    distinctUntilChanged(),
    switchMap(type =>
      this.forceDisplay() ? of([{ componentName: this.wrapper().widgetName }]) : this.widget.getAvailable(type),
    ),
    map(available => available.filter(w => w.componentName === this.wrapper().widgetName).length === 1),
  );

  treeNode = this.tree.getSelectedNode;
  subscriptions: Subscription[] = [];
  dialogRef?: MatDialogRef<any>;

  /**
   *
   */
  ngOnInit() {
    if (this.wrapper().inputData?.forceDisplay) {
      this.forceDisplay.set(true);
    }
    // Show settings as dialog when in fullscreen
    this.subscriptions.push(
      combineLatest([this.wrapper().fullscreen$, this.$showSettings]).subscribe(([full, val]) => {
        if (full && val) {
          const classNames = ['settings-item-dialog'];
          if (full) {
            classNames.push('fullscreen');
          }
          this.dialogRef = this.dialog.open(this.settingsRef(), {
            panelClass: classNames,
          });
          this.dialogRef.afterOpened().subscribe(() => {
            // Apply DragDrop
            const dialogEl = document.querySelector('.mdc-dialog__container') as HTMLElement;
            const dialog = new ElementRef<HTMLElement>(dialogEl);
            dialog.nativeElement.parentElement?.classList.add('draggable-dialog');
            const dragHandleEl = dialog.nativeElement.querySelector('.mat-mdc-dialog-title') as HTMLElement;
            const dragHandle = [new ElementRef<HTMLElement>(dragHandleEl)];
            const containerEl = document.querySelector('.cdk-overlay-pane') as HTMLElement;
            const container = new ElementRef<HTMLElement>(containerEl);
            this.drag.createDrag(dialog.nativeElement).withHandles(dragHandle).withRootElement(container);
          });
          const sub = this.dialogRef.afterClosed().subscribe(() => {
            this.showSettings = false;
            sub.unsubscribe();
            this.wrapper().settingsClosed();
            delete this.dialogRef;
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s?.unsubscribe());
    this.dialog.closeAll();
  }

  select() {
    this.el.nativeElement.closest('gridster-item').classList.add('selected');
  }

  toggleSettings($event: MouseEvent) {
    $event.stopPropagation();
    this.showSettings = !this.showSettings;
  }

  toggleGraph() {
    this.isGraphFocused.set(!this.isGraphFocused());
    this.storage.setItem('widget.graphFocused', this.isGraphFocused());
  }

  async remove() {
    const active = await firstValueFrom(this.widget.active$);
    this.widget.setActive(active.filter(w => w.componentName !== this.wrapper().widgetName));
  }
}
